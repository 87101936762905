<template>
  <v-container
    fluid
    class="primary"
    style="position: fixed; min-height: 100vh; overflow-y: scroll"
  >
    <v-row style="height: 100vh" class="ml-6">
      <v-col class="ml-6 d-flex flex-column justify-start">
        <v-card elevation="1" class="mb-12">
          <vue-json-to-csv v-if="role === 'admin' || role === 'corporate'" :json-data="usersCSV" :csv-title="'users'">
            <v-btn color="indigo" class="ma-6" outlined style="position: absolute; top: 0px; right: 0px;">
              Download
              <v-icon>mdi-excel</v-icon>
            </v-btn>
          </vue-json-to-csv>
          <v-card-title>User Management</v-card-title>
          <v-card-subtitle>Add/Edit User Accounts</v-card-subtitle>
          <v-card-text>
            <!-- <v-form ref="form" v-model="valid" lazy-validation> -->
            <v-row>
              <v-col>
                <v-data-table
                  :headers="headers"
                  :items="users"
                  class="elevation-1"
                  disable-pagination
                  :hide-default-footer="true"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-spacer></v-spacer>
                      <v-dialog v-model="dialog" max-width="500px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="indigo"
                            outlined
                            class="mb-2"
                            v-bind="attrs"
                            v-on="on"
                          >
                            New User
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title>
                            <span class="headline">{{ formTitle }}</span>
                          </v-card-title>

                          <v-card-text>
                            <v-container>
                              <v-form
                                ref="form"
                                v-model="valid"
                                lazy-validation
                              >
                                <v-row>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                      v-model="editedItem.username"
                                      label="Username (email)"
                                      :rules="[
                                        v => !!v || 'Username is required'
                                      ]"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                      v-model="editedItem.firstname"
                                      label="First Name"
                                      :rules="[
                                        v => !!v || 'First Name is required'
                                      ]"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                      v-model="editedItem.lastname"
                                      label="Last Name"
                                      :rules="[
                                        v => !!v || 'Last Name is required'
                                      ]"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-select
                                      v-model="editedItem.role"
                                      :items="roles"
                                      label="Role"
                                      :rules="[v => !!v || 'Role is required']"
                                    >
                                    </v-select>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                      v-model="editedItem.phone"
                                      label="Phone"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                      v-model="editedItem.password"
                                      label="Password"
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                              </v-form>
                            </v-container>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close">
                              Cancel
                            </v-btn>
                            <v-btn color="blue darken-1" text @click="save">
                              Save
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                          <v-card-title>Delete this user?</v-card-title>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="closeDelete"
                              >Cancel</v-btn
                            >
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="deleteItemConfirm"
                              >OK</v-btn
                            >
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-icon small class="mr-2" @click="editItem(item)">
                      mdi-pencil
                    </v-icon>
                    <v-icon small @click="deleteItem(item)">
                      mdi-delete
                    </v-icon>
                  </template>
                  <!-- <template v-slot:no-data>
                      <v-btn color="primary" @click="initialize"> Reset </v-btn>
                    </template> -->
                </v-data-table>
              </v-col>
            </v-row>
            <!-- </v-form> -->
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import helpers from "@/plugins/helpers";
const axios = require("axios").default;
axios.defaults.withCredentials = true;
import moment from "moment";
import VueJsonToCsv from "vue-json-to-csv";
export default {
  name: "Users",
  components: {
    VueJsonToCsv
  },
  data: () => ({
    valid: true,
    // firstName: "",
    // lastName: "",
    // rules: {
    //   required: (value) => !!value || "Required.",
    // },
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "Username",
        align: "start",
        value: "username"
      },
      { text: "First Name", value: "firstname" },
      { text: "Last Name", value: "lastname" },
      { text: "Role", value: "role" },
      { text: "Password Changed", value: "passwordChanged" },
      { text: "Actions", value: "actions", sortable: false }
    ],
    editedIndex: -1,
    editedItem: {
      username: "",
      firstname: "",
      lastname: "",
      role: "",
      phone: "",
      password: ""
    },
    defaultItem: {
      username: "",
      firstname: "",
      lastname: "",
      role: "",
      phone: "",
      password: ""
    },
    users: [],
    usersCSV: []
  }),
  computed: {
    token() {
      return this.$store.state.user.token;
    },
    role() {
      return this.$store.state.user.role;
    },
    roles() {
      let users = this.users;
      const unique = [...new Set(users.map(item => item.role))];
      return unique;
    },
    // users() {
    //   return this.$store.state.users;
    // },
    formTitle() {
      return this.editedIndex === -1 ? "New User" : "Edit User";
    }
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      let vm = this;
      // delete user via api call
      let user = vm.editedItem;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${vm.token}`
      };
      axios
        .delete(vm.$root.urls.api + "/user", {
          data: {
            user
          }
        })
        .then(function(results) {
          vm.logActivity(`${user.firstname} ${user.lastname} | ${user.username}`, `Deleted user`);
          vm.users.splice(vm.editedIndex, 1);
          vm.closeDelete();
        });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      let vm = this;
      let user = this.editedItem;
      let index = this.editedIndex;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${vm.token}`
      };
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          axios
            .put(vm.$root.urls.api + "/user", {
              user
            })
            .then(function(results) {
              Object.assign(vm.users[index], user);
              // toast
              vm.$toasted.show("User updated").goAway(2500);
            });
        } else {
          // save new user via API
          axios
            .post(vm.$root.urls.api + "/user", {
              user
            })
            .then(function(results) {
              vm.users.push(user);
              // toast
              vm.$toasted.show("User added").goAway(2500);
            });
        }
        this.close();
      }
    },
    logActivity(title, action = "Downloaded") {
      let vm = this;
      // log this activity
      let activity = {
        user: {
          username: `${this.$store.state.user.firstname} ${this.$store.state.user.lastname}`,
          email: this.$store.state.user.username
        },
        date: moment().format("MM/DD/YYYY"),
        action: `${action}: ${title} `,
      };
      helpers.log(vm.token, vm.$root.urls.api, activity);
      return;
    },
  },
  mounted: function() {
    let vm = this;
    // query api for users
    axios.defaults.headers.common = { Authorization: `Bearer ${vm.token}` };
    axios.get(vm.$root.urls.api + "/users", {}).then(function(results) {

      // if a user does not have a passwordChanged value, use the createdOn value
      let users = results.data;

      users.forEach( u => {
        if(!u.passwordChanged) {
          u.passwordChanged = u.createdOn
        }
        // format date with moment
        u.passwordChanged = moment(u.passwordChanged).format("MMM Do YYYY")
      })
      // prepare CSV data for download
      let csvData = [];
      users.forEach(u => {
        let row = {
          _id: u._id,
          createdOn: u.createdOn,
          darkMode: u.darkMode,
          firstname: u.firstname,
          lastname: u.lastname,
          notification: u.notification,
          passwordChanged: u.passwordChanged,
          phone: u.phone,
          role: u.role,
          unread: u.unread.length,
          username: u.username
        };
        csvData.push(row);
      });
      vm.usersCSV = csvData;
      vm.users = users;
    });
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  }
};
</script>
